import React from 'react';
import { Col, Container, ResponsiveEmbed, Row } from 'react-bootstrap';
import { Disqus, Footer, Layout, Navbar, PageHeader, Poster, Sponsor } from '@components';
import { orderBy } from 'lodash';
import { videoFallback } from '@variables';
import { graphql } from 'gatsby';

const ResearchPresentationPage = ({ data }) => {
  const { paper } = data.postgres;

  const authors = orderBy(paper?.authors, `order`)
    .map(author => author.title ? `${author.name}, ${author.title}` : author.name)
    .join(`; `);

  return <Layout className="research-presentation-page">
    <Navbar year={paper.year} />
    <PageHeader
      title={paper?.title}
      subTextComponent={<p>{paper?.topic} - {paper?.year}</p>}
    />

    <Container style={{ marginBottom: `1rem`, marginTop: `3rem` }}>
      {
        paper?.videoUrl &&
          <Row className="mb-4 justify-content-center">
            <Col lg={8} style={{ marginBottom: `1rem` }}>
              <ResponsiveEmbed aspectRatio="16by9">
                <iframe
                  title={paper?.title}
                  src={videoFallback ? paper?.backupVideoUrl : paper?.videoUrl}
                  className="embed-responsive-item"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                />
              </ResponsiveEmbed>
            </Col>
          </Row>
      }
      <Row className="mb-4 align-items-center">
        <Col lg={paper?.posterFile ? 6 : 12}>
          <h2>Abstract</h2>
          <p>
            {paper?.abstract}
          </p>
        </Col>
        {paper?.posterFile && <Col lg={6} style={{ marginBottom: `1rem` }}>
          <Poster poster={paper?.posterFile} />
        </Col>}
      </Row>
      <Row className="mb-4">
        <Col>
          <p><strong>{ paper?.authors.length > 1 ? `Authors` : `Author` }: </strong>{authors}</p>
        </Col>
      </Row>
      <Row className="mb-4 ml-0">
        {paper.topic === `10-Minute Talks` && paper.judgingSessionId &&
          <p><a href={`/judging/${paper.judgingSessionId}`}>Click here to view the Judging session</a></p>}
      </Row>
      <Disqus
        identifier={`research-${paper?.id}`}
        title={paper?.title}
        pageLocation={`/research/${paper?.id}`}
      />
    </Container>

    <Sponsor year={paper.year} />
    <Footer />

  </Layout>;
};

export const query = graphql`
  query ResearchPresentationPageQuery($paperId: Int!) {
    postgres {
      paper: researchProjectById(id: $paperId) {
        id
        title
        topic
        year
        abstract
        videoUrl
        backupVideoUrl
        poster
        posterFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        authors: researchAuthorsByResearchIdList {
          id
          title
          order
          name
        }
        judgingSessionId
      }
    }
  }
`;

export default ResearchPresentationPage;
